.ring h2{
    font-size: 30px;
    line-height: 59px;
    font-weight: 600;
}
.price h3{
    color: #F3B76A;
    font-size: 37px;
    font-weight: 600;
    line-height: 63px;
}
.tk h5{
    color: #EFF003;
    font-size: 37px;
    font-weight: 600;
    line-height: 49px;
}

@media (max-width: 767px) {
    .ring h2{
        font-size: 25px;
    }
    .price h3{
        font-size: 32px;
    }
    .tk h5{
        font-size: 32px;
    }
  }