.gift h2 {
  font-size: 40px;
  color: #f86501;
}

.order .button {
  background-color: #fb9c00;
}

.diamond {
  background-color: #f981a0;
  margin: 0 100px;
  padding: 10px 20px;
}
.diamond h6 {
  line-height: 45px;
}

@media (max-width: 767px) {
  .diamond {
    margin: 0 10px;
  }
}
