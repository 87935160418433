.agrimOrder{
    background-color: #f981a0;
} 
.agrimOrder h2{
    font-size: 35px;
    font-weight: 600;
}
.contact{
    text-align: center;
    margin: 18px 150px !important;
}

@media (max-width: 767px) {
    .agrimOrder h2{
        font-size: 28px;
    }

    .contact{
        margin: 18px 20px !important;
    }
  }