.fixed-button {
  position: fixed;
  bottom: 90px;
  right: 20px;
  z-index: 999;
}
.fixed-button .add-employee-btn,
.fixed-button .add-employee-cross-btn {
  position: relative;
  padding: 15px 25px;
  border: none;
  background-color: #86CD91 !important;
  color: black !important;
  border: black !important;
  border-radius: 50px;
  cursor: pointer;
  overflow: hidden;
  transition: padding-left 0.3s ease, transform 0.3s ease;
}
.fixed-button .add-employee-btn svg,
.fixed-button .add-employee-cross-btn svg {
  font-size: 20px;
  font-weight: 900;
  transition: transform 0.3s ease;
}
.fixed-button .add-employee-btn .text,
.fixed-button .add-employee-cross-btn .text {
  position: absolute;
  left: calc(100% + 10px);
  font-size: 17px;
  font-weight: 700;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity 0.3s ease, left 0.3s ease;
}
.fixed-button .add-employee-btn:hover {
  padding-left: 240px; /* Increase padding on hover */
}
.fixed-button .add-employee-cross-btn:hover {
  padding-left: 80px; /* Increase padding on hover */
}
.fixed-button .add-employee-btn:hover .text,
.fixed-button .add-employee-cross-btn:hover .text {
  opacity: 1;
  left: 0; /* Show text to the left of the button */
}
/* ====== phone ======  */
.fixed-phone-button {
  position: fixed;
  bottom: 170px;
  right: 20px;
  z-index: 999;
}
.fixed-phone-button .add-employee-phone-btn {
  position: relative;
  padding: 10px 20px;
  border: none;
  background-color: #46E78B !important;
  color: black !important;
  border: black !important;
  border-radius: 50px;
  cursor: pointer;
  overflow: hidden;
  transition: padding-left 0.3s ease, transform 0.3s ease;
}
.fixed-phone-button .add-employee-phone-btn svg {
  font-size: 20px;
  font-weight: 900;
  transition: transform 0.3s ease;
}
.fixed-phone-button .add-employee-phone-btn .text {
  position: absolute;
  left: calc(100% + 10px);
  font-size: 17px;
  font-weight: 700;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity 0.3s ease, left 0.3s ease;
}
.fixed-phone-button .add-employee-phone-btn:hover {
  padding-left: 70px; /* Increase padding on hover */
}
.fixed-phone-button .add-employee-phone-btn:hover .text {
  opacity: 1;
  left: 0; /* Show text to the left of the button */
}
/* ==== whatsapp ==== */
.fixed-wp-button {
  position: fixed;
  bottom: 240px;
  right: 20px;
  z-index: 999;
}
.fixed-wp-button .add-employee-wp-btn {
  position: relative;
  padding: 10px 20px;
  border: none;
  background-color: #49E670 !important;
  color: black !important;
  border: black !important;
  border-radius: 50px;
  cursor: pointer;
  overflow: hidden;
  transition: padding-left 0.3s ease, transform 0.3s ease;
}
.fixed-wp-button .add-employee-wp-btn svg {
  font-size: 20px;
  font-weight: 900;
  transition: transform 0.3s ease;
}
.fixed-wp-button .add-employee-wp-btn .text {
  position: absolute;
  left: calc(100% + 10px);
  font-size: 17px;
  font-weight: 700;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity 0.3s ease, left 0.3s ease;
}
.fixed-wp-button .add-employee-wp-btn:hover {
  padding-left: 100px; /* Increase padding on hover */
}
.fixed-wp-button .add-employee-wp-btn:hover .text {
  opacity: 1;
  left: 0; /* Show text to the left of the button */
}