.order2 .button {
  background-color: #fb9c00;
}
.sizeBall {
  background-color: #f981a0;
  font-size: 37px;
}
.sizeBall h2 {
  font-size: 32px;
  font-weight: 600;
}

.swiper {
  width: 100%;
  height: 100%;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 767px) {
  .sizeBall h2 {
    font-size: 25px;
  }
}
