.mainFrom_table {
  background-color: #f7ebfb;
}
.mainFrom_table .form {
  width: 45%;
}
.mainFrom_table .form input,textarea {
  outline: none;
  border: 1px solid gray !important;
}

.mainFrom_table .form .odrerForm .table {
  width: 45%;
}
.mainFrom_table .table {
  background-color: #f7ebfb;
}
.mainFrom_table .table .table_row h5 {
  font-size: 15px;
}
hr {
  margin: 8px;
}
.bbbb {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
}

@media (max-width: 767px) {
  .orderh1 h1 {
    font-size: 30px;
  }

  .hey {
    width: 100% !important;
  }

  .mainFrom_table {
    flex-direction: column;
  }

  .form {
    width: 100% !important;
  }

  .table {
    width: 100% !important;
  }
}
