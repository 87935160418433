.phone {
    background-color: #7b1a7e;
    color: white;
    border-radius: 80px;
    padding: 15px 10px !important;
    border-width: 5px;
    border-color: white;
    border-style: solid;
    border-style: outset;
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.5);
    gap: 5px;
    width: 30%;
  }
  
  h2,
  .phone-text {
    font-size: 30px;
    font-weight: 700;
    text-align: center;
  }
  .phone-icon {
    text-align: center;
    font-size: 35px;
    color: white;
  }
  a {
    text-decoration: none !important;
    color: white;
  }
  .whatsapp_icon button {
    outline: none !important;
    width: 70px;
    height: 70px;
  }
  
  .chat_icon:button {
    outline: none !important;
  }
  
  @media (max-width: 769px) {
    .phone {
      width: 35% !important;
    }
    .phone-icon {
      font-size: 25px;
    }
    .phone-text {
      font-size: 15px;
    }
  }
  
  @media (max-width: 500px) {
    .phone {
      width: 50% !important;
      padding: 10px;
    }
    .phone-icon {
      font-size: 20px;
    }
    .phone-text {
      font-size: 15px;
    }
  }
  